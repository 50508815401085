import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
// import ScheduleModal from '../modal/schedule'
import logo from '../../images/logo.png'
import { Navbar } from 'reactstrap'
import SideBarMenu from '../sidebar/sidebar'
// import CertificateStateSelection from '../certificate-state-selection'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
// import { faClock } from "@fortawesome/free-solid-svg-icons"

const Header = () => {
  const [scrollY, setScrollY] = useState(0)

  function logit() {
    setScrollY(window.pageYOffset)
  }
  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', logit)
    }
    watchScroll()
    // Remove listener (like componentWillUnmount)
    return () => {
      window.removeEventListener('scroll', logit)
    }
  }, [])

  //   const [scheduleShow, setScheduleShow] = useState(false)

  return (
    <>
      {/* <div className="navbar-wrapper"> */}
      <Navbar
        fixed="top"
        className={`py-3 navbar-wrapper ${
          // scrollY > 0 ? "navbar-scrolled" : "navbar-still"
          scrollY > 0 ? 'navbar-scrolled' : 'navbar-scrolled'
        }`}
      >
        <div className="nav d-flex flex-wrap w-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-10 col-9 mr-lg-auto mr-md-auto">
                <div className="mr-auto">
                  <Link to="/">
                    <img
                      // width={scrollY > 0 ? "100%" : "100%"}
                      id="logo-size"
                      src={logo}
                      alt="Sandy Scott"
                      className="logo sepia img-fluid w-75"
                    />
                  </Link>
                  {/* <div className="text-right text-xl-left d-block d-xl-flex justify-content-center align-items-center button-wrapper-desktop">
                  Testing only
                </div> */}
                </div>
              </div>
              <div className="col-lg-8 col-md-8 text-lg-right d-lg-block d-md-none d-none align-self-center">
                <Link
                  to="/about-us"
                  className="d-lg-inline-block d-md-inline-block d-block btn-default mr-3"
                >
                  About Us
                </Link>
                <Link
                  to="/resources"
                  className="d-lg-inline d-md-inline-block d-block mr-3 btn-default border-only-btn"
                >
                  Free Resources
                </Link>
                <Link
                  to="/request"
                  className="d-lg-inline d-md-inline-block d-block btn-default btn-purple"
                >
                  Free Session
                </Link>
              </div>
              <div className="col-lg-1 col-md-2 col-3 d-lg-none d-md-block text-lg-right text-md-right align-self-center">
                <SideBarMenu />
              </div>
            </div>
          </div>
        </div>
      </Navbar>
      {/* </div> */}
    </>
  )
}

export default Header
