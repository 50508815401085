import React from 'react'
import { Link } from 'gatsby'
// import { UncontrolledCollapse } from "reactstrap"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import ScheduleModal from '../modal/schedule'

// import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
// import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

// import { faTimes } from '@fortawesome/free-solid-svg-icons'
// import logo from '../../images/logo.png'
import deleteIcon from '../../images/new-homepage/delete.svg'

const Menu = ({ toggle }) => {
  const menus = [
    {
      title: 'Home',
      to: '/',
      subMenus: [],
    },
    // {
    //   title: 'Executive Coaching',
    //   to: '/executive-coaching',
    //   subMenus: [],
    // },
    // {
    //   title: 'Dyad Coaching',
    //   to: '/the-power-of-two',
    //   subMenus: [],
    // },
    // {
    //   title: 'New Role Coaching',
    //   to: '/onboarding-made-easy',
    //   subMenus: [],
    // },
    // {
    //   title: "carriers",
    //   to: "/#",
    //   subMenus: [],
    // },
    // {
    //   title: 'Solutions for Organizations',
    //   to: '/cohort-programs',
    //   subMenus: [],
    // },
    {
      title: 'About Us',
      to: '/about-new',
      subMenus: [],
    },
    // {
    //   title: 'Contact Us',
    //   to: '/#contact-us',
    //   subMenus: [],
    // },
  ]

  const SubMenu = ({ title, subMenus }) => {
    return (
      <div>
        <div
          id={title}
          style={{
            padding: '15px 8px 15px 16px',
            borderBottom: 'solid 1px #3C3735',
            color: '#818181',
            cursor: 'pointer',
          }}
          className="text-capitalize"
        >
          {title}
        </div>
        {/* <UncontrolledCollapse toggler={title}>
          <div className="d-flex flex-column">
            {subMenus.map((item, index) => (
              <MenuLink key={index} to={item.to}>
                {item.title}
              </MenuLink>
            ))}
          </div>
        </UncontrolledCollapse> */}
      </div>
    )
  }

  const MenuLink = ({ toggle, to, children }) => {
    return (
      <Link
        onClick={toggle}
        onKeyPress={toggle}
        className="text-capitalize menu-links text-left"
        to={to}
      >
        {children}
      </Link>
    )
  }

  // const [scheduleShow, setScheduleShow] = useState(false)

  return (
    <>
      <div
        className="sidemenu-nav-wrapper d-flex flex-column px-5 pb-3"
        style={{ paddingTop: '5rem' }}
      >
        <button
          onClick={() => toggle()}
          onKeyPress={toggle}
          className="close-btn"
        >
          {/* <img src={deleteIcon} alt="close" onClick={toggle} /> */}
          <img src={deleteIcon} alt="close" />
        </button>
        <div className="close-box">
          {/* <FontAwesomeIcon
            className="close-btn"
            icon={faTimes}
            onClick={toggle}
          /> */}
        </div>
        {menus.map((item, index) => {
          if (item.subMenus.length > 0)
            return (
              <SubMenu
                toggle={toggle}
                title={item.title}
                subMenus={item.subMenus}
              />
            )
          return (
            <MenuLink toggle={toggle} key={index} to={item.to}>
              {item.title}
            </MenuLink>
          )
        })}
      </div>
      <div className="px-5">
        <Link
          to="/resources"
          className="d-lg-inline d-md-block d-block btn-default border-only-btn text-center"
        >
          Free Resources
        </Link>
        <Link
          to="/request"
          className="d-lg-inline d-md-block d-block btn-default btn-purple mt-4 text-center"
        >
          Free Session
        </Link>
      </div>
    </>
  )
}

export default Menu
