import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import './style.css'
import 'font-awesome/css/font-awesome.css'
// import ScrollAnimation from 'react-animate-on-scroll'

import Header from './header/header'
import Footer from './Footer'

// import Footer from './Footer'

class Layout extends React.Component {
  render() {
    // const { location, title, children } = this.props
    // const rootPath = `${__PATH_PREFIX__}/`

    const { children } = this.props
    let header

    // if (location.pathname === rootPath) {
    //   header = (
    //     <h1
    //       style={{
    //         ...scale(1.5),
    //         marginBottom: rhythm(1.5),
    //         marginTop: 0,
    //       }}
    //     >
    //       <Link
    //         style={{
    //           boxShadow: `none`,
    //           textDecoration: `none`,
    //           color: `inherit`,
    //         }}
    //         to={`/`}
    //       >
    //         {title}
    //       </Link>
    //     </h1>
    //   )
    // } else {
    //   header = (
    //     <h3
    //       style={{
    //         fontFamily: `Montserrat, sans-serif`,
    //         marginTop: 0,
    //       }}
    //     >
    //       <Link
    //         style={{
    //           boxShadow: `none`,
    //           textDecoration: `none`,
    //           color: `inherit`,
    //         }}
    //         to={`/`}
    //       >
    //         {title}
    //       </Link>
    //     </h3>
    //   )
    // }
    return (
      <div id="top">
        <Header />
        {header}
        {children}
        <Footer />
      </div>
    )
  }
}

export default Layout
