import { Link } from 'gatsby'
import React, { useEffect } from 'react'
//forms
// import ResourcesForm from '../components/forms/resources-form'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Logo from '../images/logo.png'
import PhoneIcon from '../images/new-homepage/call-icon.svg'
// import JoinGroupForm from './forms/joinGroupForm.jsxxx'
//icons
import EmailAddIcon from '../images/new-homepage/email-icon.svg'
import InboxIcon from '../images/new-homepage/inbox-icon.svg'

const Footer = () => {
  const _linkedin_partner_id = '522666'

  useEffect(() => {
    if (window) {
      window._linkedin_data_partner_ids =
        window._linkedin_data_partner_ids || []
      window._linkedin_data_partner_ids.push(_linkedin_partner_id)
    }
    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.asnyc = true
    script.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'
    var tag = document.getElementsByTagName('script')[0]
    tag.parentNode.insertBefore(script, tag)
  })

  return (
    <footer>
      <div class="footer text-white position-relative">
        <section className="footer-info">
          <article>
            <div
              className="container"
              style={{
                borderBottom: '5px solid #9c6543',
                background: '#fff',
              }}
            >
              <div className="row">
                <div className="col-lg-4 col-md-6 px-lg-5 py-4">
                  <div className="d-flex">
                    <LazyLoadImage
                      alt="Email"
                      className="mr-4"
                      style={{ width: '2.5rem', height: '2.5rem' }}
                      src={EmailAddIcon}
                    />
                    <div>
                      <h5>Email address</h5>
                      <p>support@sandyscottllc.com</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 px-lg-5 py-4">
                  <div className="d-flex justify-content-lg-center justify-content-md-center justify-content-start">
                    <LazyLoadImage
                      alt="Phone"
                      className="mr-4"
                      style={{ width: '2.5rem', height: '2.5rem' }}
                      src={PhoneIcon}
                    />
                    <div>
                      <h5>Phone</h5>
                      <p>9702199477</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-8 px-lg-5 py-4 mx-lg-0 mx-md-auto mx-auto">
                  <div className="d-flex">
                    <LazyLoadImage
                      alt="Phone"
                      className="mr-4"
                      src={InboxIcon}
                      style={{ width: '2.5rem', height: '2.5rem' }}
                    />
                    <div>
                      <h5>Inbox</h5>
                      <p>PO Box 415, Bellvue, CO 80512</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
        {/* <div class="container footer-contact pb-4">
            <div class="row">
              <div class="col-md-4">
                <ul class="contact-details">
                  <li class="email">
                    <span class="contact-span">Email address</span>
                    <a href="mailto:support@sandyscottllc.com" title="Email">
                      support@sandyscottllc.com
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <ul class="contact-details">
                  <li class="phone">
                    <span class="contact-span">Phone</span>
                    <a href="tel:9702199477" title="Contact number">
                      9702199477
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <ul class="contact-details">
                  <li class="address">
                    <span class="contact-span">Inbox</span>PO Box 415, Bellvue, CO
                    80512
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        <div class="container pt-5 pb-5">
          <div class="row">
            <div class="col-lg-4 col-md-4 px-lg-0 px-md-0 px-auto">
              <img
                class="img-fluid footer-logo"
                src={Logo}
                alt="Sandy Scott LLC"
              />
              <p class="pt-3 mb-0">
                A talent and leadership development firm, improving quality
                metrics at health systems through research-based leadership
                training, transformational coaching experiences and proven
                consulting solutions.
              </p>
              {/* <div>
                  <a
                    class="mr-3 social-icon linkedin"
                    href="https://www.linkedin.com/in/sandy-scott-fache/"
                    target="_blank"
                    rel="noreferrer noopener"
                    title="Linkedin "
                  >
                    <i class="fa fa-linkedin" />
                  </a>
                  <a
                    class="mr-3 social-icon facebook"
                    href="https://www.facebook.com/profile.php?id=347665778902063"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Facebook"
                  >
                    <i class="fa fa-facebook" />
                  </a>
                  <a
                    class="social-icon instagram"
                    href="https://www.instagram.com/highimpactphysicians/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Instagram"
                  >
                    <i class="fa fa-instagram" />
                  </a>
                </div> */}
            </div>
            <div class="col-lg-4 col-md-4 px-lg-0 px-md-0 px-auto">
              <div className="w-50 mx-auto helpful-links-wrapper my-lg-0 my-md-0 my-4">
                <h5 class="text-white">Helpful Links</h5>
                <ul class="footer-links my-0">
                  <li>
                    <Link title="Home" to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link title="About us" to="/about-us">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link title="Free Resources" to="/resources">
                      Free Resources
                    </Link>
                  </li>
                  <li>
                    <Link title="Free Session" to="/request">
                      Free Session
                    </Link>
                  </li>
                  {/* <li>
                      <Link title="Contact us" to="/#contact-us">
                        Contact Us
                      </Link>
                    </li> */}
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 px-lg-0 px-md-0 px-auto">
              <h5 class="text-white">Get Free, Tools and Case Studies</h5>
              <div
              // id="resource-form-wrapper"
              // style={{
              //   height: '90vh',
              //   marginTop: '10rem',
              // }}
              >
                <iframe
                  src="../../footer-resources-form.html"
                  title="Resources Form"
                  width="100%"
                  border="none"
                  className="w-100"
                  style={{ border: 'none', height: '18rem', marginBottom: 0 }}
                />
              </div>
              {/* <JoinGroupForm /> */}
              {/* <ResourcesForm
                  submitBtn="btn-default btn-brown btn-block d-block"
                  btntitle="Subscribe"
                /> */}
            </div>
          </div>
        </div>
      </div>
      <div class="copyright-section">
        <div class="container py-3" style={{ borderTop: '1px solid #fff' }}>
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 py-2 text-center text-md-left">
              <span class="d-block">
                &copy; {new Date().getFullYear()} Sandy Scott LLC. All rights
                reserved.
              </span>
            </div>
            <div className="col-lg-6 col-md-6 text-lg-right text-md-right text-center">
              <span class="d-block">
                Designed and Powered by{' '}
                <a
                  href="http://webriq.com"
                  target="_blank"
                  alt="WebriQ"
                  rel="noopener noreferrer"
                >
                  WebriQ
                </a>
              </span>
            </div>
            {/* <div class="col-md-6 py-2 text-center text-md-right order-1 order-md-2">
                <span class="d-block">
                  <a class="back-to-top" href="#top" title="back-to-top">
                    Scroll to Top
                    <i class="fa fa-arrow-up pl-1" />
                  </a>
                </span>
              </div> */}
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
